import PopoverInfo from "@src/components/form/popover-info/PopoverInfo";

function replaceInfoWithComponent(input: string): JSX.Element {
  // Regular Expression to match the [info=...] tag
  const infoRegex = /\[info=(.*?)\]/;

  // Find the match
  const match = input.match(infoRegex);

  if (!match) {
    // If no match is found, return the text as is
    return <span>{input}</span>;
  }

  // Extract the information from the tag
  const [fullMatch, infoContent] = match;

  // Split the input text into parts: before, the info content, and after
  const beforeText = input.slice(0, match.index);
  const afterText = input.slice(match.index! + fullMatch.length);

  return (
    <span>
      {beforeText}
      <PopoverInfo noSpacing title="Hinweis" text={infoContent} />
      {afterText}
    </span>
  );
}

export default replaceInfoWithComponent;
